import { ChakraProvider } from "@chakra-ui/react";
import { makeOperation } from "@urql/core";
import { authExchange } from "@urql/exchange-auth";
import { StrictMode } from "react";
import { Cookies, CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
  Provider as UrqlProvider,
} from "urql";
import App from "./App";
import { theme } from "./theme";
import "./index.css";
import { TOKEN_KEY } from "src/const"

interface AuthState {
  token: string;
}

const authConfig = {
  getAuth: async ({ authState }: any) => {
    if (!authState) {
      const cookies = new Cookies();
      const token = cookies.get(TOKEN_KEY);
      if (token) {
        return { token };
      }
      return null;
    }
    return null;
  },
  didAuthError: ({ error }: any) => {
    return error.graphQLErrors?.[0]?.extensions?.status === 401;
  },
  addAuthToOperation: ({ authState, operation }: any) => {
    if (!authState || !authState.token) {
      return operation;
    }

    const fetchOptions =
      typeof operation.context.fetchOptions === "function"
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};

    return makeOperation(operation.kind, operation, {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          Authorization: `bearer ${authState.token}`,
        },
      },
    });
  },
};

const client = createClient({
  url: process.env.REACT_APP_AGASA_API_URL ?? "http://localhost:5001/query",
  exchanges: [
    dedupExchange,
    cacheExchange,
    authExchange<AuthState>(authConfig),
    fetchExchange,
  ],
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <StrictMode>
    <CookiesProvider>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <UrqlProvider value={client}>
            <App />
          </UrqlProvider>
        </BrowserRouter>
      </ChakraProvider>
    </CookiesProvider>
  </StrictMode>
);
