import { Box, Flex, Text } from "@chakra-ui/react";
import { addZero } from "src/util/textUtil";
import { TimeStampType } from "src/interface/TimeStamp";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { DAY_OF_WEEKS } from "src/const";

export const TimeBox = ({
  calendarRef,
  playTimestamp,
  setSelectedTimestamp,
  isCalenderOpen,
  onClick,
  onClose,
}: {
  calendarRef: React.RefObject<HTMLDivElement>;
  playTimestamp: TimeStampType | undefined;
  setSelectedTimestamp: (selectedTimestamp: TimeStampType | undefined) => void;
  isCalenderOpen: boolean;
  onClick: () => void;
  onClose: () => void;
}) => {
  const Today = new Date();
  const [date, setDate] = useState(Today);

  return (
    <Box w="100%" ref={calendarRef} onClick={onClick} cursor="pointer">
      {playTimestamp && (
        <Flex justify="center">
          <Flex
            borderBottom="1px solid #000000"
            _hover={{ borderBottom: "0px" }}
            alignItems="flex-end"
            cursor="pointer"
          >
            <Text fontSize="16px">
              {playTimestamp?.year}年{addZero(playTimestamp?.month)}月
              {addZero(playTimestamp?.date)}日 ({playTimestamp?.dayOfWeek})
            </Text>
            <Text fontSize="24px" ml="8px">
              {addZero(playTimestamp?.hour)}:{addZero(playTimestamp?.minute)}:
              {addZero(playTimestamp?.second)}
            </Text>
          </Flex>
          {isCalenderOpen && (
            <Box position="absolute" bottom="90px" zIndex={100}>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                selected={date}
                maxDate={Today}
                customInput={<></>}
                onChange={(date) => {
                  console.log("calender", date);
                  if (date) {
                    setSelectedTimestamp({
                      year: date.getFullYear(),
                      month: date.getMonth() + 1,
                      date: date.getDate(),
                      dayOfWeek: DAY_OF_WEEKS[date.getDay()],
                      hour: playTimestamp.hour,
                      minute: playTimestamp.minute,
                      second: playTimestamp.second,
                    });
                    setDate(date);
                  }
                  onClose();
                }}
                inline
              />
            </Box>
          )}
        </Flex>
      )}
    </Box>
  );
};
