import { Box, Tooltip } from "@chakra-ui/react";
import { RLiveButton } from "@timeleap-rura/rura-icons";
import { memo } from "react";

type Props = {
  isLive: boolean;
  onClick: (isLive: boolean) => void;
};

export const LiveButton = memo(({ onClick, isLive }: Props) => {
  return (
    <Tooltip
      label="ライブ映像へ"
      hasArrow
      isDisabled={isLive}
      backgroundColor="black"
    >
      <Box
        w="65px"
        mr={3}
        borderRadius="4px"
        backgroundColor={isLive ? "safie" : "#94A7BB"}
        _active={{ backgroundColor: "#6D87A2" }}
        _hover={{ cursor: "pointer" }}
        onClick={() => {
          onClick(true);
        }}
      >
        <RLiveButton color="white" />
      </Box>
    </Tooltip>
  );
});
