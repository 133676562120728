import { useExternalScript } from "src/hooks/useExternalScript";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { buildQueryString } from "src/helper/query-string";
import {
  PATH,
  ArgStatusType,
  ARG_STATUS,
  ERROR_TEXT,
  PLAYING_LIMIT_CAMERA_NUM,
} from "src/const";

const parser = new DOMParser();
const stringSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <g clip-path="url(#clip0_11548_2476)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.99967C3.64638 7.99967 3.30724 8.14015 3.05719 8.3902C2.80714 8.64025 2.66667 8.97939 2.66667 9.33301V22.6663C2.66667 23.02 2.80714 23.3591 3.05719 23.6091C3.30724 23.8592 3.64638 23.9997 4 23.9997H18.6667C19.0203 23.9997 19.3594 23.8592 19.6095 23.6091C19.8595 23.3591 20 23.02 20 22.6663V21.333C20 20.5966 20.597 19.9997 21.3333 19.9997C22.0697 19.9997 22.6667 20.5966 22.6667 21.333V22.6663C22.6667 23.7272 22.2452 24.7446 21.4951 25.4948C20.7449 26.2449 19.7275 26.6663 18.6667 26.6663H4C2.93913 26.6663 1.92172 26.2449 1.17157 25.4948C0.421428 24.7446 0 23.7272 0 22.6663V9.33301C0 8.27214 0.421427 7.25473 1.17157 6.50458C1.92172 5.75444 2.93913 5.33301 4 5.33301H6.66667C7.40305 5.33301 8 5.92996 8 6.66634C8 7.40272 7.40305 7.99967 6.66667 7.99967H4ZM12.88 6.66634C12.88 5.92996 13.477 5.33301 14.2133 5.33301H18.6667C19.7275 5.33301 20.7449 5.75444 21.4951 6.50458C22.2452 7.25473 22.6667 8.27214 22.6667 9.33301V13.2341L22.806 13.3733L29.8852 8.25267C30.2911 7.95912 30.8272 7.91777 31.2732 8.14564C31.7193 8.3735 32 8.83213 32 9.33301V22.6663C32 23.4027 31.403 23.9997 30.6667 23.9997C29.9303 23.9997 29.3333 23.4027 29.3333 22.6663V11.943L23.4481 16.2C22.9176 16.5838 22.1869 16.5255 21.7239 16.0625L20.3905 14.7292C20.1405 14.4791 20 14.14 20 13.7863V9.33301C20 8.97939 19.8595 8.64025 19.6095 8.3902C19.3594 8.14015 19.0203 7.99967 18.6667 7.99967H14.2133C13.477 7.99967 12.88 7.40272 12.88 6.66634Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.390524 0.390524C0.911223 -0.130175 1.75544 -0.130175 2.27614 0.390524L31.6095 29.7239C32.1302 30.2446 32.1302 31.0888 31.6095 31.6095C31.0888 32.1302 30.2446 32.1302 29.7239 31.6095L0.390524 2.27614C-0.130175 1.75544 -0.130175 0.911223 0.390524 0.390524Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_11548_2476">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
        </svg>`;
const NAME_NODE_HEIGHT = 36;

export const useCameraDevices = (
  videoRef: React.RefObject<HTMLDivElement> | null,
  shopName: string | null,
  safieApiKey: { status: ArgStatusType; data: string | undefined },
  assignApiKey: string | null
) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [userInfo, setUserInfo] = useState<
    { mailAddress: string; userName: string }[]
  >([]);
  const [playerList, setPlayerList] = useState<
    { player: any; node: HTMLDivElement; deviceId: string }[]
  >([]);
  const [devices, setDevices] = useState<
    {
      deviceId: string;
      setting: { name: string };
      status: { videoStreaming: boolean };
    }[]
  >([]);
  const [width, setWidth] = useState(0);
  const [cameraHeight, setCameraHeight] = useState(0);
  const isScriptLoaded = useExternalScript(
    "https://safie.link/sdk/js/api/v1/latest/"
  );
  const win: any = window;
  const [playingVideo, setPlayingVideo] = useState(0);

  useEffect(() => {
    if (playerList.length === 1 && playerList[0].player) {
      // ビデオが一つ かつ 再生中の場合自動遷移する
      selectCamera(playerList[0].deviceId);
    }
  }, [playerList]);

  const playerRef = useRef(null);
  const deviceSelectRef = useRef(null);

  const initialize = async () => {
    // tokenの設定とユーザ情報の取得
    try {
      const { tokenType } = await win.Safie.Auth.initialize();
      // content.style.display = '';
      if (tokenType === win.Safie.Auth.TokenType.AccessToken) {
        await getUserInfo();
      }
    } catch (error) {
      console.error("initialize error", error);
      setErrorText(ERROR_TEXT.INITIALIZE_ERROR);
    }
  };

  const getUserInfo = async () => {
    try {
      const userInfo = await win.Safie.Users.queryInformation();
      setUserInfo(userInfo);
    } catch (error) {
      console.error("userInfo error", error);
    }
  };

  const getDevices = async () => {
    try {
      const devices = await win.Safie.Devices.queryDevices({ limit: 100 });
      if (devices.list.length === 0) {
        // カメラが一つもなければ、エラーを返す
        setErrorText(ERROR_TEXT.DISCOLLABORATE_SAFIE_CAMERA);
        setIsLoading(false);
        return;
      }
      if (shopName) {
        // _前で一致
        const shopNameStrList = shopName.split("_");
        const partOfShopName = shopNameStrList[0];

        // 全文一致
        const shopDeviceList = devices.list.filter(
          (device: {
            deviceId: string;
            setting: { name: string };
            status: { videoStreaming: boolean };
          }) =>
            device.setting.name.includes(shopName) ||
            device.setting.name.includes(partOfShopName)
        );

        if (shopDeviceList.length === 0) {
          // filter後のカメラが一つもなければ、エラーを返す
          setErrorText(ERROR_TEXT.FILTER_SAFIE_CAMERA_IS_NONE);
          setIsLoading(false);
          return;
        }
        setDevices(shopDeviceList);
      } else {
        setDevices(devices.list);
      }
    } catch (error) {
      console.error("getDevices error", error);
    }
  };

  const selectCamera = (deviceId: string) => {
    const params = new URLSearchParams();

    params.append("device_id", deviceId);
    if (assignApiKey) {
      params.append("api-key", assignApiKey);
    }
    history.push(buildQueryString(PATH.STREAMING, params));
  };

  const setToken = async () => {
    // const token = process.env.REACT_APP_SAFIE_API_KEY;
    if (
      safieApiKey.status !== ARG_STATUS.SUCCESS &&
      safieApiKey.status !== ARG_STATUS.ASSIGN
    ) {
      console.log("apikey error", safieApiKey);
      return false;
    }
    const token = assignApiKey ?? safieApiKey.data;
    console.log("setToken", token);
    if (token == null || token === "") {
      return false;
    }

    try {
      await win.Safie.Auth.setToken(token, "apiKey");
    } catch (error) {
      console.error("setToken error", error);
    }

    return true;
  };

  const removeToken = async () => {
    await win.Safie.Auth.removeToken();
  };

  const addPlayer = async () => {
    let player: any = null;
    try {
      if (devices.length === 0) {
        return;
      }
      if (playerList.length > 0) {
        playerList.map((playerItem) => {
          if (playerItem.player) {
            playerItem.player.dispose();
          }
          playerItem.node.remove();
        });
        setPlayerList([]);
      }
      // 再生中videoの数
      let tmpPlayingVideo = 0;
      let cameraWidth = 280;
      if (width) {
        const blocks = devices.length < 3 ? devices.length : 3;
        cameraWidth = width / blocks - 20;
      }
      let cameraHeight = (cameraWidth * 9) / 16 + NAME_NODE_HEIGHT;
      setCameraHeight(cameraHeight);
      // カメラのwidthとheightを調整する
      if (videoRef && videoRef.current) {
        console.log("width", cameraHeight, videoRef.current.clientWidth);
      }
      devices.map(async (device, index: number) => {
        const isStreaming: boolean = device.status.videoStreaming;
        // 店舗カメラノード(divNode)
        const divNode = document.createElement("div");
        divNode.style.margin = "8px";
        divNode.style.cursor = "pointer";
        divNode.style.display = "block";
        divNode.style.width = `${cameraWidth}px`;
        divNode.style.height = `${cameraHeight}px`;
        divNode.style.borderRadius = "4px";
        divNode.style.overflow = "hidden"; // 子要素によりborder-radiusが効かないので設定

        if (isStreaming) {
          divNode.addEventListener("click", () =>
            selectCamera(device.deviceId)
          );
        }

        // 動画ノード
        const videoNode = document.createElement("div");
        videoNode.style.width = `${cameraWidth}px`;
        videoNode.style.height = `${(cameraWidth * 9) / 16}px`;
        videoNode.style.aspectRatio = "16/9";
        videoNode.style.pointerEvents = "none";

        // 非接続ノード
        const disconnectNode = document.createElement("div");
        disconnectNode.style.width = `${cameraWidth}px`;
        disconnectNode.style.height = `${(cameraWidth * 9) / 16}px`;
        disconnectNode.style.aspectRatio = "16/9";
        disconnectNode.style.background = "#37352F";
        disconnectNode.style.pointerEvents = "none";
        disconnectNode.style.textAlign = "center";
        disconnectNode.style.display = "flex";
        disconnectNode.style.flexDirection = "column";
        disconnectNode.style.alignItems = "center";
        disconnectNode.style.justifyContent = "center";
        disconnectNode.style.color = "#FFFFFF";

        /** 非接続ノードの説明文の要素 */
        const descriptionElement = document.createElement("div");
        descriptionElement.textContent = "未接続";
        descriptionElement.style.marginTop = "8px";
        descriptionElement.style.fontSize = "14px";
        descriptionElement.style.fontWeight = "500";

        /** 非接続ノード用のアイコン */
        const svgIcon = parser.parseFromString(
          stringSvg,
          "image/svg+xml"
        ).documentElement;

        disconnectNode.appendChild(svgIcon);
        disconnectNode.appendChild(descriptionElement);

        // 非再生ノード
        const notPlayNode = document.createElement("div");
        notPlayNode.style.width = `${cameraWidth}px`;
        notPlayNode.style.height = `${(cameraWidth * 9) / 16}px`;
        notPlayNode.style.aspectRatio = "16/9";
        notPlayNode.style.background = "#37352F";
        notPlayNode.style.color = "#FFF";
        notPlayNode.style.pointerEvents = "none";
        notPlayNode.style.textAlign = "center";
        notPlayNode.style.display = "flex";
        notPlayNode.style.flexDirection = "column";
        notPlayNode.style.justifyContent = "center";
        notPlayNode.style.alignItems = "center";

        const notPlayNodeDescription = descriptionElement.cloneNode(true);
        notPlayNodeDescription.textContent = "制限により再生できません";

        const notPlayNodeSvgIcon = svgIcon.cloneNode(true);

        notPlayNode.appendChild(notPlayNodeSvgIcon);
        notPlayNode.appendChild(notPlayNodeDescription);

        // 店舗名ノード
        const nameNode = document.createElement("div");
        nameNode.style.width = `${cameraWidth}px`;
        nameNode.style.height = `${NAME_NODE_HEIGHT}px`;
        nameNode.style.background = "#FFFFFF";
        nameNode.style.padding = "8px";
        nameNode.style.fontWeight = "500";
        nameNode.style.fontSize = "14px";
        nameNode.textContent = device.setting.name;
        nameNode.style.overflow = "hidden";
        nameNode.style.textOverflow = "ellipsis";
        nameNode.style.whiteSpace = "nowrap";

        if (videoRef && videoRef.current) {
          videoRef.current.append(divNode);
        }
        if (tmpPlayingVideo > PLAYING_LIMIT_CAMERA_NUM) {
          console.log(
            `同時に表示可能なカメラの数は${PLAYING_LIMIT_CAMERA_NUM}つまでです`
          );
        }
        if (isStreaming && PLAYING_LIMIT_CAMERA_NUM >= index + 1) {
          divNode.append(videoNode);
          divNode.append(nameNode);
          player = new win.Safie.Player.StreamingPlayer(videoNode);
          setPlayerList((prev) => [
            ...prev,
            { player: player, node: divNode, deviceId: device.deviceId },
          ]);
          player.deviceId = device.deviceId;
          player.muted = true;

          if (device.status.videoStreaming) {
            player.userInteractions = false;
            player.play();
            tmpPlayingVideo += 1;
            setPlayingVideo(tmpPlayingVideo);
          }
        } else if (isStreaming && PLAYING_LIMIT_CAMERA_NUM < index + 1) {
          setPlayerList((prev) => [
            ...prev,
            { player: null, node: divNode, deviceId: device.deviceId },
          ]);
          divNode.append(notPlayNode);
          divNode.append(nameNode);
        } else {
          setPlayerList((prev) => [
            ...prev,
            { player: null, node: divNode, deviceId: device.deviceId },
          ]);
          divNode.append(disconnectNode);
          divNode.append(nameNode);
        }
      });
    } catch (error: any) {
      alert(`エラーtype: ${error.type} message: ${error.message}`);
      return;
    }
  };

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!isScriptLoaded) return;
    if (!safieApiKey) return;

    (async () => {
      try {
        const result = await setToken();
        if (!result) {
          const timeout = setTimeout(() => {
            console.error("tokenの取得に失敗しました");
            setErrorText(ERROR_TEXT.API_KEY_ERROR);
            setIsLoading(false);
          }, 5000);
          timeoutRef.current = timeout;
          return;
        } else {
          setIsLoading(false);
          setErrorText(undefined);
        }
        initialize();
        // await getUserInfo();
        await getDevices();
      } catch (error: any) {
        // safie側で設定したエラーをとるために、error.typeを取得する
        if (error?.type === "forbidden") {
          setErrorText(ERROR_TEXT.IP_RESTRICTION_ERROR);
          setIsLoading(false);
        }
        return;
      }
    })();

    return () => {
      clearTimeout(timeoutRef.current ?? "");
    };
  }, [isScriptLoaded, safieApiKey]);

  useEffect(() => {
    if (devices.length === 0) return;
    setIsLoading(false);
    addPlayer();
  }, [devices, width]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setWidth(entry.contentRect.width);
      }
    });
    if (videoRef && videoRef.current) {
      resizeObserver.observe(videoRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [videoRef]);

  return {
    isScriptLoaded,
    playerRef,
    deviceSelectRef,
    addPlayer,
    setToken,
    removeToken,
    getDevices,
    devices,
    isLoading,
    errorText,
    playingVideo,
    width,
    cameraHeight,
  };
};
