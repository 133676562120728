import { Box, Flex, Text } from "@chakra-ui/react";
import { createTimes } from "src/util/createTimes";
import { useState, useRef, useMemo, useEffect } from "react";
import { addZero } from "src/util/textUtil";
import { TimeStampType } from "src/interface/TimeStamp";
import { APP_AREA } from "src/const";

export const TimeLine = ({
  setSelectedTimestamp,
  playTimestamp,
  setPlayTimestamp,
  isPaused,
  isNotPlaying,
}: {
  setSelectedTimestamp: (selectedTimestamp: TimeStampType | undefined) => void;
  playTimestamp: TimeStampType | undefined;
  setPlayTimestamp: (playTimestamp: TimeStampType | undefined) => void;
  isPaused: boolean;
  isNotPlaying: boolean;
}) => {
  const times = createTimes();

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const calculateSelectedTime = () => {
    if (scrollContainerRef.current) {
      const center = scrollContainerRef.current?.clientWidth / 2;
      const lineXPosition = scrollContainerRef?.current?.scrollLeft;
      const index = Math.floor(lineXPosition / 20);
      if (times.length > index) {
        const selected = times[index];
        return selected;
      }
    }
    return undefined;
  };

  useEffect(() => {
    // playTimestampの時間でスクロールを遷移させる
    if (!playTimestamp) return;
    if (isDragging) return;
    const scrollX =
      20 * (playTimestamp.hour * 60 + playTimestamp.minute) +
      (playTimestamp.second * 20) / 60;

    if (scrollContainerRef && scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = scrollX;
      setScrollLeft(scrollContainerRef.current.scrollLeft);
    }
  }, [playTimestamp, isDragging]);

  const handleMouseDown = (e: any) => {
    if (scrollContainerRef && scrollContainerRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
      setScrollLeft(scrollContainerRef.current.scrollLeft);
    }
  };

  const handleMouseMove = (e: any) => {
    if (!isDragging) return;
    if (scrollContainerRef && scrollContainerRef.current) {
      const x = e.pageX - scrollContainerRef.current.offsetLeft;
      const walk = (x - startX) * 2; // スクロール速度を調整するための倍率
      scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    const selectedTime = calculateSelectedTime();
    if (!selectedTime || !playTimestamp) return;

    const timestampData = {
      year: playTimestamp.year,
      month: playTimestamp.month,
      date: playTimestamp.date,
      dayOfWeek: playTimestamp.dayOfWeek,
      hour: selectedTime.hour,
      minute: selectedTime.minute,
      second: 0,
    };

    setSelectedTimestamp(timestampData);
    // 一時停止中(playerが一時停止しており、ストリーミング再生もされていないとき）
    // は、playTimeChangeイベントが発火せず、再生位置を変更出来ないようなので、ここで再生位置を変更
    if (isPaused && isNotPlaying) {
      setPlayTimestamp(timestampData);
    }
  };

  const isOneMinutesBefore = (time: { hour: number; minute: number }) => {
    // 現在の1分前しか録画がないので、それを判定する関数
    // 現在の時刻を取得
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    // 現在の時刻との比較
    if (currentHour < time.hour) {
      // 未来の時刻はすべて false
      return false;
    } else if (currentHour === time.hour) {
      // 同じ時刻の場合、分の比較
      return currentMinute >= time.minute + 1;
    } else {
      // 現在の時刻が指定された時間よりも前の場合は true
      return true;
    }
  };

  return (
    <Box position="relative" height={APP_AREA.TIMELINE_HEIGHT}>
      <Box
        borderBottom="1px solid #000000"
        overflowX="hidden"
        flexWrap="nowrap"
        ref={scrollContainerRef}
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <div
          style={{
            display: "inline-flex",
            cursor: "ew-resize",
            paddingLeft: `${
              scrollContainerRef.current
                ? scrollContainerRef.current.clientWidth / 2
                : 0
            }px`,
            paddingRight: `${
              scrollContainerRef.current
                ? scrollContainerRef.current.clientWidth / 2
                : 0
            }px`,
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          {times.map((time) => (
            <Box w="20px" key={time.hour * 60 + time.minute}>
              <Box
                w="100%"
                h="50px"
                borderBottom={
                  isOneMinutesBefore(time)
                    ? "10px solid #d0e898"
                    : "2px solid #b4b4b5"
                }
                // borderLeft={
                //   selectedTime.hour === time.hour &&
                //   selectedTime.minute === time.minute
                //     ? "10px solid green"
                //     : ""
                // }
              ></Box>
              <Box w="100%" h="20px">
                <Box w="100%" h="10px" borderLeft="1px solid #000000"></Box>
                {time.minute % 5 === 0 && (
                  <Box w="100%" h="10px" borderLeft="1px solid #000000"></Box>
                )}
              </Box>
              <Box w="100" h="30px">
                {time.minute % 10 === 0 && (
                  <Text
                    ml="-10px"
                    fontSize="10px"
                    overflowWrap="normal"
                  >{`${addZero(time.hour)}:${addZero(time.minute)}`}</Text>
                )}
              </Box>
            </Box>
          ))}
        </div>
      </Box>
      <Box
        w="5px"
        h="50px"
        background="#b1546e"
        position="absolute"
        top="0px"
        left="50%"
        zIndex={1}
      />
    </Box>
  );
};
