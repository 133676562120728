import { useState, useEffect, useMemo } from "react";
import { gql } from "urql";
import {
  useGetCustomVariableQuery,
  useMeQuery,
} from "src/gql/generated/graphql";
import { PATH, TOKEN_KEY, COMPANY_VARIABLE_KEY, ARG_STATUS } from "src/const";
import { useHistory } from "react-router-dom";
import { cookieKeyToken } from "src/util/token";
import { Cookies } from "react-cookie";
import { isBlank } from "src/util/textUtil";

gql`
  query GetCustomVariable($variable: String!, $owner: ID!) {
    customVariable(variable: $variable, owner: $owner) {
      value
    }
  }
`;

export const MeQuery = gql`
  fragment MeRoomField on Room {
    id
    datastoreId
    name
  }

  fragment MeField on User {
    id
    datastoreId
    name
    profile {
      presignedIcon
    }
    roomGroup {
      rooms {
        ...MeRoomField
      }
    }
    company {
      id
      datastoreId
      name
    }
  }

  query me {
    me {
      ...MeField
    }
  }
`;

export const useSafieApiKey = () => {
  const [me, setMe] = useState<any>(undefined);
  const [result] = useMeQuery();
  const history = useHistory();

  useEffect(() => {
    const cookie = new Cookies();
    let token = cookie.get(TOKEN_KEY);

    // NOTE: RURA Web の接客画面から ifarame で呼び出される場合は、RURA Web 側のトークンを優先する。
    if (window.parent != null) {
      const ruraToken = cookie.get<string>(cookieKeyToken);
      if (ruraToken && token !== ruraToken) {
        cookie.set(TOKEN_KEY, ruraToken, {
          maxAge: 10 * 24 * 60 * 60,
          sameSite: "none",
          secure: true,
        });
        window.location.reload();
        return;
      }
    }

    if (!token) {
      const colaboToken = cookie.get(cookieKeyToken);
      if (colaboToken) {
        cookie.set(TOKEN_KEY, colaboToken ?? "", {
          maxAge: 10 * 24 * 60 * 60,
          sameSite: "none",
          secure: true,
        });
        token = colaboToken;
        window.location.reload();
      }
    }

    if (!token) {
      history.push(PATH.LOGIN);
    }
  }, []);

  useEffect(() => {
    if (result.data?.me === undefined) return;
    setMe(result.data.me);
  }, [result]);

  const [{ data }] = useGetCustomVariableQuery({
    variables: { variable: COMPANY_VARIABLE_KEY, owner: me?.company?.id },
    pause: isBlank(me?.company?.id),
    requestPolicy: "cache-and-network",
  });

  const safieApiKey = useMemo(() => {
    if (!data) return { status: ARG_STATUS.INIT, data: undefined };

    const api_key = data.customVariable.value ?? undefined;
    if (!api_key) return { status: ARG_STATUS.ERROR, data: undefined };

    return { status: ARG_STATUS.SUCCESS, data: api_key };
  }, [data]);

  return { safieApiKey };
};
