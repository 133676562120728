import { useState, useEffect } from "react";
import { Box, Text, Link } from "@chakra-ui/react";
import { useLogin } from "src/pages/Login/hooks/useLogin";
import { InputArea } from "src/components/InputArea";
import { Header } from "src/components/LoginHeader";
import { PATH } from "src/const";
import { Link as ReactLink } from "react-router-dom";

function Login() {
  const [isActive, setActive] = useState<boolean>(true);
  const {
    login,
    userId,
    setUserId,
    password,
    setPassword,
    shopName,
    setShopName,
    alertText,
    apiKey,
    setApiKey,
  } = useLogin();
  useEffect(() => {
    if (!isActive && userId && password) {
      setActive(true);
    } else if (isActive && (!userId || !password)) {
      setActive(false);
    }
  }, [userId, password]);

  return (
    <Box textAlign="center" height="95vh">
      <Header text="入力して接続開始を押してください。" />
      <Box
        textAlign="left"
        w="390px"
        m="auto"
        boxShadow="0px 1px 6px rgba(0, 0, 0, 0.16)"
        borderRadius="4px"
      >
        <Box p="10px 10px 30px 10px">
          <Box p="22px 28px 20px">
            <InputArea
              text="スタッフID"
              defaultValue={userId}
              handleChange={(e) => {
                setUserId(e.target.value);
              }}
              placeholderText="スタッフID"
            />
          </Box>
          <Box p="0 28px 31px">
            <InputArea
              text="パスワード"
              defaultValue={password}
              handleChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholderText="パスワード"
              onEnter={() => {
                if (isActive) {
                  login(PATH.CAMERA_DEVICES);
                }
              }}
            />
          </Box>
          <Box p="0 28px 31px">
            <InputArea
              text="フィルター用の店舗名"
              defaultValue={shopName}
              handleChange={(e) => {
                setShopName(e.target.value);
              }}
              placeholderText="店舗名"
              onEnter={() => {
                if (isActive) {
                  login(PATH.CAMERA_DEVICES);
                }
              }}
            />
          </Box>
          <Box p="0 28px 31px">
            <InputArea
              text="APIキーを指定する"
              defaultValue={apiKey}
              handleChange={(e) => {
                setApiKey(e.target.value);
              }}
              placeholderText="safie APIキー"
              onEnter={() => {
                if (isActive) {
                  login(PATH.CAMERA_DEVICES);
                }
              }}
            />
            <Text>※APIキーを指定すると優先して参照されます</Text>
          </Box>
          <Box h="50px">
            <Box
              float="right"
              w="100px"
              mr="28px"
              mb="20px"
              h="40px"
              mt="5px"
              background={isActive ? "#00A0E9" : "rgba(0, 0, 0, 0.12)"}
              borderRadius="4px"
              cursor={isActive ? "pointer" : "not-allowed"}
              _hover={{
                bg: isActive
                  ? "rgba(0, 160, 233, 0.50)"
                  : "rgba(0, 0, 0, 0.12)",
              }}
              onClick={() => {
                if (isActive) {
                  login(PATH.CAMERA_DEVICES);
                }
              }}
            >
              <Text
                pt="5px"
                fontSize="16px"
                fontWeight="400"
                color={isActive ? "#FFFFFF" : "rgba(0, 0, 0, 0.26)"}
                textAlign="center"
              >
                ログイン
              </Text>
            </Box>
          </Box>

          {alertText && (
            <Text mt="15px" color="red" textAlign="center">
              {alertText}
            </Text>
          )}
        </Box>
      </Box>
      <Box m="32px auto" textAlign="center" width="380px">
        <Box textAlign="center">
          <Link
            fontSize="14px"
            color="primary.500"
            cursor="pointer"
            textAlign="center"
            as={ReactLink}
            to={process.env.REACT_APP_PASSWORD_RESET_URL ?? ""}
          >
            パスワードをお忘れの場合
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
