import { Spinner, SpinnerProps } from "@chakra-ui/react";
import { FC, memo } from "react";

type LoadingProps = {
  /**
   * サイズ（デフォルト: "xl"）
   */
  size?: SpinnerProps["size"];

  /**
   * スピード（デフォルト: 1.0s）
   */
  speed?: SpinnerProps["speed"];

  /**
   * 色（デフォルト: "#008C9500"）
   */
  color?: SpinnerProps["color"];
};

/**
 * ローディング
 */
export const Loading: FC<LoadingProps> = memo((props) => {
  const { size = "xl", speed = "1.0s", color = "#008C95" } = props;

  return <Spinner size={size} color={color} speed={speed} />;
});
