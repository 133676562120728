export const APP_AREA = {
  HEADER_HEIGHT: 56,
  HEIGHT: 609,
  ALL_HEIGHT: 714,
  TEXT_AREA_HEIGHT: 120,
  VIDEO_CONTROL_BAR_HEIGHT: 32,
  TIMELINE_HEIGHT: 100,
  TIMEBOX_HEIGHT: 80,
} as const;

export const PATH = {
  CAMERA_DEVICES: "/",
  LOGIN: "/login",
  STREAMING: "/streaming",
} as const;

export const TOKEN_KEY = "safie-app-token";
export const COMPANY_VARIABLE_KEY = "safie_api_key";
export const PLAYING_LIMIT_CAMERA_NUM = 6;
export const LOCAL_MEMORY = {
  SHOP_NAME: "shop-name",
} as const;

export const FRAME_RECEIVE_ACTION = {
  SAFIE_INIT_DATA: "safie_init_data",
} as const;

export const FRAME_SEND_ACTION = {
  OPEN_SAFIE: "open_safie",
} as const;

export const ApproveFrameReceiveAction = [
  FRAME_RECEIVE_ACTION.SAFIE_INIT_DATA,
];

export const DAY_OF_WEEKS = ["日", "月", "火", "水", "木", "金", "土"];


export const ARG_STATUS = {
  ASSIGN: "assign",
  ERROR: "error",
  SUCCESS: "success",
  INIT: "init"
} as const;

export type ArgStatusType = (typeof ARG_STATUS)[keyof typeof ARG_STATUS];

export const ERROR_TEXT = {
  LOGIN_ERROR_SIGNIN_FAILED: "スタッフIDまたはパスワードが間違っています。",
  LOGIN_ERROR_SIGNIN_FROZEN:
    "ログイン試行回数の上限を超えました。管理者に連絡してください。",
  LOGIN_ERROR_SIGNIN_REJECTION:
    "許可されたIPアドレスではないのでログインできません。管理者に連絡してください。",
  API_KEY_ERROR:
    "Safieカメラが取得できません。管理者に連絡してください。(SA-001)",
  DISCOLLABORATE_SAFIE_CAMERA:
    "Safieカメラが取得できません。管理者に連絡してください。(SA-002)",
  FILTER_SAFIE_CAMERA_IS_NONE:
    "Safieカメラが見つかりません。管理者に連絡してください。(SA-003)",
  INITIALIZE_ERROR:
    "Safieカメラが見つかりません。管理者に連絡してください。(SA-004)",
  FILTER_SAFIE_CAMERA_IS_NONE_ON_STREAM:
    "映像を取得できません。一覧画面から再度接続してください。",
  IP_RESTRICTION_ERROR:
    "現在のネットワークからカメラへのアクセスができません(SA-004)",
};