export const createTimes = () => {
  // 全ての分数を作成してリストに追加する関数
  const times = [];
  // 開始時間
  let currentTime = { hour: 0, minute: 0 };

  // 24時間分の時間を生成
  for (let i = 0; i < 24 * 60; i++) {
    times.push({ ...currentTime }); // 現在の時間を配列に追加

    // 分を1つ進める（例：30分→31分）
    currentTime.minute += 1;

    // 60分経過したら時を進める（例：9時59分→10時0分）
    if (currentTime.minute === 60) {
      currentTime.hour += 1;
      currentTime.minute = 0;
    }

    // 24時になったら0時に戻す
    if (currentTime.hour === 24) {
      currentTime.hour = 0;
    }
  }

  return times;
}