import { Box, Icon, IconButton, IconButtonProps, Text } from "@chakra-ui/react";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import {
  RReplay10,
  RReplay60,
  RRewindForward10,
  RRewindForward60,
  RLive,
} from "@timeleap-rura/rura-icons";
import { FC } from "react";
import { TimeStampType } from "src/interface/TimeStamp";
import { convertPlayTimestampToTimeStamp } from "src/util/convertPlayTimestampToTimeStamp";

/** 再生時間によって、表示すべきボタンの区分 */
const manipulationButtonStatus = {
  ALL_REPLAY_FORWARD_ENABLE: ["replay60", "replay10", "forward10", "forward60"],
  REPLAY60_REPLAY10_FORWARD10_ENABLE: [
    "replay60",
    "replay10",
    "forward10",
    "live",
  ],
  REPLAY60_REPLAY10_ENABLE: ["replay60", "replay10", "live"],
  ONLY_REPLAY60_ENABLE: ["replay60", "replay10", "forward10", "forward60"],
} as const;

type ManipulationButtonStatus = keyof typeof manipulationButtonStatus;

type ButtonKind = "replay60" | "replay10" | "forward10" | "forward60" | "live";

type Props = {
  playTimestamp?: TimeStampType;
  isHovered: boolean;
  handlePlaybackTime: (time: number) => void;
  handleLiveStreaming: () => void;
};

export const PlaybackTimeControlButtons: FC<Props> = ({
  playTimestamp,
  isHovered,
  handlePlaybackTime,
  handleLiveStreaming,
}) => {
  const currentTimeStamp = Date.now();
  const playbackTimeTimestamp =
    convertPlayTimestampToTimeStamp(playTimestamp) ?? 0;
  const timeDifference = currentTimeStamp - playbackTimeTimestamp;
  const status: ManipulationButtonStatus =
    timeDifference > 120000
      ? "ALL_REPLAY_FORWARD_ENABLE"
      : timeDifference > 70000
      ? "REPLAY60_REPLAY10_FORWARD10_ENABLE"
      : timeDifference > 50000
      ? "REPLAY60_REPLAY10_ENABLE"
      : "ONLY_REPLAY60_ENABLE";
  const buttonKinds = manipulationButtonStatus[status];

  return (
    <Box
      style={{
        position: "absolute",
        bottom: "16px",
        left: "50%",
        zIndex: 5,
        transform: "translate(-50%, 0%)",
        display: isHovered ? "block" : "none",
        width: "256px",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: "8px",
        borderRadius: "8px",
      }}
    >
      {status === "ONLY_REPLAY60_ENABLE" && (
        <Text
          color={"#FFF"}
          fontWeight={400}
          fontSize={"12px"}
          lineHeight={"14.52px"}
        >
          LIVE映像から60秒以内は早送りや巻き戻しはできません。
        </Text>
      )}
      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "16px",
          padding: "8px",
        }}
      >
        {buttonKinds.map((kind) => (
          <ControlButton
            key={kind}
            status={status}
            kind={kind}
            onPlayback={handlePlaybackTime}
            onLiveStreaming={handleLiveStreaming}
          />
        ))}
      </Box>
    </Box>
  );
};

type ControlButtonProps = {
  kind: ButtonKind;
  status: ManipulationButtonStatus;
  onPlayback: (num: number) => void;
  onLiveStreaming: () => void;
};

// 各種操作ボタンを返すコンポーネント
const ControlButton: FC<ControlButtonProps> = (props) => {
  const status = props.status;

  switch (props.kind) {
    case "replay60":
      // どのステータスでもreplay60は使える
      return (
        <ControlIconButton
          icon={<RReplay60 />}
          aria-label="rreplay60"
          onClick={() => props.onPlayback(-60.5)}
        />
      );
    case "replay10": {
      // onlyReplay60の時はreplay10は使えない
      const isDisabled = status === "ONLY_REPLAY60_ENABLE";
      return (
        <ControlIconButton
          icon={<RReplay10 />}
          aria-label="rreplay10"
          onClick={() => props.onPlayback(-10)}
          isDisabled={isDisabled}
        />
      );
    }
    case "forward10": {
      const isDisabled =
        status === "ONLY_REPLAY60_ENABLE" ||
        status === "REPLAY60_REPLAY10_ENABLE";
      return (
        <ControlIconButton
          icon={<RRewindForward10 />}
          aria-label="rewindForward10"
          onClick={() => props.onPlayback(10)}
          isDisabled={isDisabled}
        />
      );
    }
    case "forward60": {
      const isDisabled =
        status === "ONLY_REPLAY60_ENABLE" ||
        status === "REPLAY60_REPLAY10_ENABLE" ||
        status === "REPLAY60_REPLAY10_FORWARD10_ENABLE";
      return (
        <ControlIconButton
          icon={<RRewindForward60 />}
          aria-label="rewindForward60"
          onClick={() => props.onPlayback(60)}
          isDisabled={isDisabled}
        />
      );
    }
    case "live":
      return (
        <ControlIconButton
          icon={<RLive />}
          aria-label="live"
          onClick={props.onLiveStreaming}
          style={{ gridColumn: "4 / 5" }}
        />
      );
    default:
      return null;
  }
};

const ControlIconButton: FC<IconButtonProps> = (props) => {
  return (
    <IconButton
      variant="ghost"
      color="rgba(255, 255, 255, 0.8)"
      _disabled={{ color: "rgba(255, 255, 255, 0.3)" }}
      _hover={{
        background: "transparent",
        cursor: props.isDisabled ? "default" : "pointer",
      }}
      _active={{ background: "transparent" }}
      {...props}
    />
  );
};
