import { Button, Flex, Text } from "@chakra-ui/react";
import { Cookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { APP_AREA, LOCAL_MEMORY, PATH, TOKEN_KEY } from "src/const";
import { buildQueryString } from "src/helper/query-string";
import { useQueryString } from "src/hooks/useQueryString";
import { SafieLogoHorizontal } from "./logo/SafieLogoHorizontal";
import { IoIosArrowBack } from "react-icons/io";

export const Header = ({
  isViewOnFrame,
  text,
  removeToken,
  showBackButton,
}: {
  isViewOnFrame: boolean;
  text: string;
  removeToken: () => void;
  showBackButton?: boolean;
}) => {
  const history = useHistory();
  const apiKey = useQueryString("api-key");
  const logout = () => {
    if (!isViewOnFrame) {
      const cookie = new Cookies();
      cookie.remove(TOKEN_KEY, {
        maxAge: 10 * 24 * 60 * 60,
        sameSite: "none",
        secure: true,
      });
      removeToken();
      history.push(PATH.LOGIN);
    } else {
      // iframe上なので、閉じるmessageを送信する
      removeToken();
    }
    localStorage.removeItem(LOCAL_MEMORY.SHOP_NAME);

    return;
  };

  const nextCameraDevices = () => {
    const params = new URLSearchParams();
    const shopName = localStorage.getItem(LOCAL_MEMORY.SHOP_NAME);

    if (shopName || apiKey) {
      if (shopName) params.append("shop-name", shopName);
      if (apiKey) params.append("api-key", apiKey);
      history.push(buildQueryString(PATH.CAMERA_DEVICES, params));
    } else {
      history.push(PATH.CAMERA_DEVICES);
    }
  };

  return (
    <Flex
      w="100%"
      h={`${APP_AREA.HEADER_HEIGHT}px`}
      p="16px"
      justifyContent="space-between"
    >
      <Flex alignItems="center" gap={"16px"}>
        {showBackButton && (
          <IoIosArrowBack
            onClick={nextCameraDevices}
            style={{ cursor: "pointer" }}
          />
        )}
        <Text fontSize={18}>{text}</Text>
      </Flex>
      <Flex alignItems="center" gap="1rem">
        <SafieLogoHorizontal />
        {!isViewOnFrame && <Button onClick={logout}>ログアウト</Button>}
      </Flex>
    </Flex>
  );
};
