import { useEvent } from "react-use";
import { useEffect } from "react";
import {
  ApproveFrameReceiveAction,
  FRAME_RECEIVE_ACTION,
  FRAME_SEND_ACTION,
} from "src/const";
import { actionMessageType } from "src/interface/Messages"
import DateTime from "src/util/dateTime";

export const useMessageEvent = ({
  setShopName,
}: {
  setShopName?: (shopName: string) => void;
}) => {
  const isViewOnFrame = window !== window.parent;
  const nowTime = () => {
    const dateTime = new DateTime();
    return dateTime.toString(new Date());
  };

  useEffect(() => {
    if (!isViewOnFrame) return;
    if (!setShopName) return;
    sendMessage({
      action: FRAME_SEND_ACTION.OPEN_SAFIE,
      from: "rura_chat",
      time: nowTime(),
      data: null,
    });
  }, [isViewOnFrame, setShopName]);

  const sendMessage = (message: actionMessageType) => {
    window.parent.postMessage(JSON.stringify(message), "*");
  };

  useEvent(
    "message",
    (event: any) => {
      if (event.data) {
        try {
          const message = JSON.parse(event.data);
          if (
            message.action &&
            ApproveFrameReceiveAction.includes(message.action)
          ) {
            if (message.action === FRAME_RECEIVE_ACTION.SAFIE_INIT_DATA) {
              console.log(FRAME_RECEIVE_ACTION.SAFIE_INIT_DATA, message.data);
              if (!setShopName) return;
              setShopName(message.data.pcName);
            }
          }
        } catch (error) {
          return;
        }
      }
    },
    window
  );
};
