import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CameraDevices from "src/pages/CameraDevices";
import { PATH } from "src/const";
import Login from "src/pages/Login";
import Streaming from "src/pages/Streaming";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={PATH.LOGIN}>
          <Login />
        </Route>
        <Route exact path={PATH.CAMERA_DEVICES}>
          <CameraDevices />
        </Route>
        <Route exact path={PATH.STREAMING}>
          <Streaming />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
