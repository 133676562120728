import { useQueryString } from "src/hooks/useQueryString"
import { useEffect, useState } from "react";
import { LOCAL_MEMORY } from "src/const"

export const useParams = () => {
  const shopNameOnParam = useQueryString("shop-name");
  const deviceId = useQueryString("device_id");
  const apiKey = useQueryString("api-key");
  const [shopName, setShopName] = useState<string | null>(
    shopNameOnParam ?? null
  );

  useEffect(() => {
    const shopName = localStorage.getItem(LOCAL_MEMORY.SHOP_NAME);
    if (shopName) {
      setShopName(shopName);
    }
   }, [])
  
  return {
    shopName,
    setShopName,
    deviceId,
    apiKey,
  };
};
