import { Box, Flex, Text } from "@chakra-ui/react";
import { useMemo, useRef } from "react";
import { Header } from "src/components/Header";
import { Loading } from "src/components/loader/Loading";
import { APP_AREA, PLAYING_LIMIT_CAMERA_NUM } from "src/const";
import { useMessageEvent } from "src/hooks/useMessageEvent";
import { useParams } from "src/hooks/useParams";
import { useSafieApiKey } from "src/hooks/useSafieApiKey";
import { useCameraDevices } from "./hooks/useCameraDevices";

function Main() {
  const videoRef = useRef<HTMLDivElement>(null);
  const { shopName, setShopName, apiKey } = useParams();
  const { safieApiKey } = useSafieApiKey();
  useMessageEvent({ setShopName });
  const {
    devices,
    isLoading,
    removeToken,
    errorText,
    playingVideo,
    cameraHeight,
  } = useCameraDevices(videoRef, shopName, safieApiKey, apiKey);
  const isViewOnFrame = window !== window.parent;

  const connectedDevices = useMemo(() => {
    return devices.filter((device) => device.status.videoStreaming);
  }, [devices]);

  const disconnectedDevices = useMemo(() => {
    return devices.filter((device) => !device.status.videoStreaming);
  }, [devices]);

  return (
    <Box h="100vh">
      <Header
        isViewOnFrame={isViewOnFrame}
        text="カメラ一覧"
        removeToken={removeToken}
      />
      <Box
        p="16px"
        background="#EBEFF3"
        h={
          devices.length > 3
            ? `${((cameraHeight + 100) * devices.length) / 3}px`
            : `calc(100vh - ${APP_AREA.HEADER_HEIGHT}px)`
        }
      >
        <Flex alignItems="flex-end" display="flex" h="24px" p="8px">
          <Text fontSize={14}>デバイス数</Text>
          <Box m="0px 8px" h="24px">
            <Text fontSize={20} mt="-4px" fontWeight={700}>
              {devices.length}
            </Text>
          </Box>
          <Box>
            <Text fontSize={14}>
              ( 接続中 {connectedDevices.length} / 未接続
              {disconnectedDevices.length} / 再生中 {playingVideo} )
              {playingVideo > PLAYING_LIMIT_CAMERA_NUM &&
                `※同時に再生可能なカメラは${PLAYING_LIMIT_CAMERA_NUM}台までです`}
            </Text>
          </Box>
        </Flex>
        {!isViewOnFrame && (
          <Box>
            {shopName && shopName !== shopName.split("_")[0] && (
              <Text fontSize={14} ml="8px">
                ※店舗名:「{shopName}」/_前の店舗名:「{shopName.split("_")[0]}
                」でフィルター中です
              </Text>
            )}
            {shopName && shopName === shopName.split("_")[0] && (
              <Text fontSize={14} ml="8px">
                ※店舗名:「{shopName}」でフィルター中です
              </Text>
            )}
          </Box>
        )}
        {isLoading && (
          <Box w="100%" mt="300px">
            <Box w="50px" m="auto">
              <Loading />
            </Box>
          </Box>
        )}
        {errorText && (
          <Box w="100%" h="100%">
            <Box w="300px" m="300px auto">
              <Text>{errorText}</Text>
            </Box>
          </Box>
        )}
        <Flex
          ref={videoRef}
          w="100%"
          style={{ display: "flex" }}
          flexWrap="wrap"
        />
      </Box>
    </Box>
  );
}

export default Main;
