import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { Header } from "src/components/Header";
import { TimeBox } from "src/components/TimeBox";
import { TimeLine } from "src/components/TimeLine";
import { Loading } from "src/components/loader/Loading";
import { APP_AREA, LOCAL_MEMORY, PATH } from "src/const";
import { buildQueryString } from "src/helper/query-string";
import { useMessageEvent } from "src/hooks/useMessageEvent";
import { useParams } from "src/hooks/useParams";
import { useSafieApiKey } from "src/hooks/useSafieApiKey";
import { useStreaming } from "./hooks/useStreaming";
import { LiveButton } from "./components/LiveButton";
import { IoPause, IoPlay } from "react-icons/io5";
import { PlaybackTimeControlButtons } from "./components/PlaybackTimeControlButtons";
import { useClickAway } from "react-use";
import { VolumeBar } from "./components/VolumeBar";

function Main() {
  const history = useHistory();
  const videoRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);
  const [isCalenderOpen, setIsCalenderOpen] = useState<boolean>(false);
  const { deviceId, apiKey } = useParams();
  const { safieApiKey } = useSafieApiKey();
  useMessageEvent({});
  useClickAway(calendarRef, () => {
    setIsCalenderOpen(false);
  });
  const {
    device,
    isLoading,
    setSelectedTimestamp,
    isLive,
    handleLiveStreaming,
    errorText,
    playTimestamp,
    removeToken,
    isPaused,
    isNotPlaying,
    handlePause,
    handleUnpause,
    setPlayTimestamp,
    handlePlaybackTime,
    volume,
    handleChangeVolume,
    muted,
  } = useStreaming(videoRef, deviceId, safieApiKey, apiKey);
  const isViewOnFrame = window !== window.parent;

  const [isHovered, setIsHovered] = useState(false);
  const [isShowVolumeBar, setIsShowVolumeBar] = useState(false);

  const handleToggleCalender = () => {
    setIsCalenderOpen(!isCalenderOpen);
  };

  const handleCloseCalender = () => {
    setIsCalenderOpen(false);
  };

  return (
    <Box h="100vh">
      <Header
        isViewOnFrame={isViewOnFrame}
        text={device?.setting.name ?? ""}
        removeToken={removeToken}
        showBackButton
      />

      <Box background="#EBEFF3" h={`calc(100vh - ${APP_AREA.HEADER_HEIGHT}px)`}>
        {isLoading && (
          <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
            <Box w="50px" m="auto">
              <Loading />
            </Box>
          </Flex>
        )}
        {errorText && (
          <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
            <Text>{errorText}</Text>
          </Flex>
        )}

        <Box
          w="100%"
          // NOTE: 条件分岐によってこのBox要素を出し入れすると、safieのiframeによって生成される動画領域が表示されなくなる
          // そのため、displayプロパティを使い、存在はさせておく
          style={{ display: !isLoading && !errorText ? "block" : "none" }}
        >
          <Box
            ref={videoRef}
            w="100%"
            style={{ position: "relative" }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <PlaybackTimeControlButtons
              handlePlaybackTime={handlePlaybackTime}
              playTimestamp={playTimestamp}
              handleLiveStreaming={handleLiveStreaming}
              isHovered={isHovered}
            />
          </Box>
          <Box>
            <Flex
              background="#333333"
              alignItems="center"
              px="8px"
              py="4px"
              onMouseLeave={() => setIsShowVolumeBar(false)}
              height={`${APP_AREA.VIDEO_CONTROL_BAR_HEIGHT}px`}
            >
              <Box style={{ marginRight: "12px" }}>
                {isPaused ? (
                  <IoPlay
                    color="rgba(255, 255, 255, 0.8)"
                    onClick={handleUnpause}
                    size={20}
                  />
                ) : (
                  <IoPause
                    color="rgba(255, 255, 255, 0.8)"
                    onClick={handlePause}
                    size={20}
                  />
                )}
              </Box>
              <Text color={isLive ? "#888888" : "#FFFFFF"} fontWeight={600}>
                x1
              </Text>
              <Text color="#FFFFFF" fontWeight={600}>
                :
              </Text>
              <Flex
                cursor="pointer"
                onClick={handleLiveStreaming}
                color={isLive ? "#FFFFFF" : "#888888"}
              >
                <Text fontWeight={600} ml="8px">
                  LIVE
                </Text>
                <IoPlay style={{ margin: "4px 0px 0px 4px" }} />
              </Flex>
              <Spacer />
              <VolumeBar
                showVolumeSlider={isShowVolumeBar}
                setShowVolumeSlider={setIsShowVolumeBar}
                onChange={handleChangeVolume}
                volume={volume}
                muted={muted}
                handlePause={handlePause}
              />
            </Flex>
            <TimeLine
              setSelectedTimestamp={setSelectedTimestamp}
              playTimestamp={playTimestamp}
              setPlayTimestamp={setPlayTimestamp}
              isPaused={isPaused}
              isNotPlaying={isNotPlaying}
            />
            <Box
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr 1fr",
                height: APP_AREA.TIMEBOX_HEIGHT,
              }}
            >
              <Box style={{ gridColumn: 2, alignSelf: "center" }}>
                <TimeBox
                  calendarRef={calendarRef}
                  playTimestamp={playTimestamp}
                  setSelectedTimestamp={setSelectedTimestamp}
                  isCalenderOpen={isCalenderOpen}
                  onClick={handleToggleCalender}
                  onClose={handleCloseCalender}
                />
              </Box>
              <Box style={{ alignSelf: "center", justifySelf: "end" }}>
                <LiveButton onClick={handleLiveStreaming} isLive={isLive} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Main;
