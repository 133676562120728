import { useExternalScript } from "src/hooks/useExternalScript";
import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { addZero } from "src/util/textUtil";
import { TimeStampType } from "src/interface/TimeStamp";
import {
  DAY_OF_WEEKS,
  ArgStatusType,
  ARG_STATUS,
  ERROR_TEXT,
  PATH,
  APP_AREA,
} from "src/const";
import { useHistory } from "react-router-dom";
import { convertPlayTimestampToTimeStamp } from "src/util/convertPlayTimestampToTimeStamp";

const HEIGHT_WITHOUT_VIDEO =
  APP_AREA.HEADER_HEIGHT +
  APP_AREA.VIDEO_CONTROL_BAR_HEIGHT +
  APP_AREA.TIMELINE_HEIGHT +
  APP_AREA.TIMEBOX_HEIGHT;

export const useStreaming = (
  videoRef: React.RefObject<HTMLDivElement> | null,
  deviceId: string | null,
  safieApiKey: { status: ArgStatusType; data: string | undefined },
  assignApiKey: string | null
) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [selectedTimestamp, setSelectedTimestamp] = useState<
    TimeStampType | undefined
  >(undefined);

  const [playTimestamp, setPlayTimestamp] = useState<TimeStampType | undefined>(
    undefined
  );

  const [tokenStatus, setTokenStatus] = useState<string>("accessToken");
  const [userInfo, setUserInfo] = useState<
    { mailAddress: string; userName: string }[]
  >([]);
  const [playerItem, setPlayerItem] = useState<
    { player: any; node: HTMLDivElement } | undefined
  >(undefined);
  const [device, setDevice] = useState<
    | {
        deviceId: string;
        setting: { name: string };
        status: { videoStreaming: boolean };
      }
    | undefined
  >(undefined);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const isScriptLoaded = useExternalScript(
    "https://safie.link/sdk/js/api/v1/latest/"
  );
  const win: any = window;

  const selectRef = useRef(null);
  const containerRef = useRef(null);
  const playerRef = useRef(null);
  const deviceSelectRef = useRef(null);

  /** ストリーミング再生のモードがliveかどうか */
  const isLive = playerItem?.player.streamingMode === "live";
  /** ストリーミング再生のモードが再生していないでないかどうか */
  const isNotPlaying = playerItem?.player.streamingMode === "none";

  /** 音量 */
  const volume = playerItem?.player.volume;

  /** ミュート */
  const muted = playerItem?.player.muted;

  const initialize = async () => {
    // tokenの設定とユーザ情報の取得
    try {
      const { tokenType } = await win.Safie.Auth.initialize();

      setTokenStatus("valid type: " + tokenType);
      // content.style.display = '';
      console.log("token type", win.Safie.Auth.TokenType.AccessToken);
      if (tokenType === win.Safie.Auth.TokenType.AccessToken) {
        await getUserInfo();
      }
    } catch (error: any) {
      setTokenStatus(`${error.type} ${error.message}`);
    }
  };

  const getUserInfo = async () => {
    try {
      const userInfo = await win.Safie.Users.queryInformation();
      setUserInfo(userInfo);
    } catch (error) {
      console.error("userInfo error", error);
    }
  };

  const getDevices = async () => {
    try {
      const devices = await win.Safie.Devices.queryDevices({ limit: 100 });
      if (devices.list.length === 0) {
        // カメラが一つもなければ、エラーを返す
        setErrorText(ERROR_TEXT.DISCOLLABORATE_SAFIE_CAMERA);
        setIsLoading(false);
        return;
      }
      if (!deviceId) return;
      const shopDevice = devices.list.find(
        (device: {
          deviceId: string;
          setting: { name: string };
          status: { videoStreaming: boolean };
        }) => device.deviceId === deviceId
      );
      if (!shopDevice) {
        alert(ERROR_TEXT.FILTER_SAFIE_CAMERA_IS_NONE_ON_STREAM);
        history.push(PATH.CAMERA_DEVICES);
        return;
      }
      setDevice(shopDevice ?? undefined);
    } catch (error) {
      console.error("getDevices error", error);
    }
  };

  const setToken = async () => {
    // const token = process.env.REACT_APP_SAFIE_API_KEY;
    if (
      safieApiKey.status !== ARG_STATUS.SUCCESS &&
      safieApiKey.status !== ARG_STATUS.ASSIGN
    )
      return false;
    const token = assignApiKey ?? safieApiKey.data;
    if (token == null || token === "") {
      return false;
    }

    await win.Safie.Auth.setToken(token, "apiKey");
    return true;
  };

  const removeToken = async () => {
    await win.Safie.Auth.removeToken();
  };

  const addPlayer = async () => {
    let player: any = null;
    try {
      if (!device) return;
      if (playerItem) {
        playerItem.player.dispose();
        playerItem.node.remove();
        setPlayerItem(undefined);
      }
      // 動画ノード
      const videoNode = document.createElement("div");
      videoNode.style.width = "100%";
      videoNode.style.height = `calc(100vh - ${HEIGHT_WITHOUT_VIDEO}px)`;
      videoNode.style.aspectRatio = "16/9";
      if (videoRef && videoRef.current) {
        videoRef.current.append(videoNode);
      }
      if (videoRef && videoRef.current) {
        player = new win.Safie.Player.StreamingPlayer(videoNode);
        const onTimeChange = (timestamp: number) => {
          let dateTime = new Date(timestamp);
          setPlayTimestamp({
            year: dateTime.getFullYear(),
            month: dateTime.getMonth() + 1,
            date: dateTime.getDate(),
            dayOfWeek: DAY_OF_WEEKS[dateTime.getDay()],
            hour: dateTime.getHours(),
            minute: dateTime.getMinutes(),
            second: dateTime.getSeconds(),
          });
        };
        player.on("playTimeChange", onTimeChange);
        setPlayerItem({ player, node: videoNode });
        player.deviceId = device.deviceId;
        player.volume = 0;
        player.muted = true;
        if (device.status.videoStreaming) {
          player.play();
          // 再生出来る状態のため、errorTextを消す
          setErrorText(undefined);
        }
      }
    } catch (error: any) {
      alert(`エラーtype: ${error.type} message: ${error.message}`);
      return;
    }
  };

  const streamTimestamp = useMemo(() => {
    if (!selectedTimestamp) return "";
    return `${selectedTimestamp.year}-${addZero(
      selectedTimestamp.month
    )}-${addZero(selectedTimestamp.date)}T${addZero(
      selectedTimestamp.hour
    )}:${addZero(selectedTimestamp.minute)}:00`;
  }, [selectedTimestamp]);

  /** Live配信に切り替えるハンドラ */
  const handleLiveStreaming = useCallback(() => {
    if (!playerItem) return;
    playerItem.player.play();
  }, [playerItem]);

  const handlePause = useCallback(() => {
    if (!playerItem) return;
    playerItem.player.pause();
  }, [playerItem]);

  const handleUnpause = useCallback(() => {
    if (!playerItem) return;
    playerItem.player.unpause();
  }, [playerItem]);

  const handleChangeVolume = useCallback(
    (volume: number) => {
      if (!playerItem) return;
      playerItem.player.volume = volume;
      if (volume === 0) {
        playerItem.player.muted = true;
      } else {
        playerItem.player.muted = false;
      }
    },
    [playerItem]
  );

  const onStatusChange = (context: { status: string }) => {
    if (context.status === "paused") {
      setIsPaused(true);
    }
    if (context.status === "streaming") {
      setIsPaused(false);
    }
  };

  useEffect(() => {
    if (!playerItem) return;
    if (!playerItem.player) return;
    playerItem.player.on("statusChange", onStatusChange);
    return () => {
      if (!playerItem) return;
      if (!playerItem.player) return;
      // NOTE: クリーンアップする際に、Cannot read properties of nul (reading 'removeListener')が出るのを防ぐため追加
      //https://safie.link/sdk/js/api/v1/1.2/doc/classes/Player.StreamingPlayer.html#dispose
      // player.dispose()を使ってインスタンスが使用不可になるため、removeListenerが使えなくなるよう
      if (playerItem?.player._emitter) {
        playerItem?.player?.off("statusChange", onStatusChange);
      }
    };
  }, [playerItem?.player]);

  /** 選択時間を操作するハンドラ */
  const handlePlaybackTime = (second: number) => {
    if (!playerItem) return;
    if (!playTimestamp) return;
    const timestamp = convertPlayTimestampToTimeStamp(playTimestamp) ?? 0;
    playerItem.player.play(timestamp + second * 1000);

    // NOTE: タイムラインと同じように停止中は,playTimeChangeイベントが発火しないため、ここで時間を更新する
    const date = new Date(timestamp + second * 1000);
    if (isPaused && isNotPlaying) {
      setPlayTimestamp({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        date: date.getDate(),
        dayOfWeek: DAY_OF_WEEKS[date.getDay()],
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds(),
      });
    }
  };

  // TODO:動画の時間変更
  useEffect(() => {
    if (!playerItem) return;
    if (!selectedTimestamp) return;

    const dateString = `${selectedTimestamp.year}-${addZero(
      selectedTimestamp.month
    )}-${addZero(selectedTimestamp.date)}T${addZero(
      selectedTimestamp.hour
    )}:${addZero(selectedTimestamp.minute)}:${addZero(
      selectedTimestamp.second
    )}`;
    const selectedData = new Date(dateString);

    // 現在時間以上かどうか
    const now = new Date();
    if (now > selectedData) {
      playerItem.player.play(selectedData.getTime());
    } else {
      playerItem.player.play();
    }
  }, [playerItem, selectedTimestamp]);

  useEffect(() => {
    if (!isScriptLoaded) return;
    if (!safieApiKey) return;
    (async () => {
      const result = await setToken();
      if (!result) {
        console.error("tokenの取得に失敗しました");
        setErrorText(ERROR_TEXT.API_KEY_ERROR);
        setIsLoading(false);
        return;
      }
      initialize();
      // await getUserInfo();
      await getDevices();
    })();
  }, [isScriptLoaded, safieApiKey]);

  useEffect(() => {
    if (!device) return;
    setIsLoading(false);
    addPlayer();
  }, [device]);

  return {
    isScriptLoaded,
    playerRef,
    deviceSelectRef,
    addPlayer,
    setToken,
    removeToken,
    getDevices,
    device,
    isLoading,
    selectedTimestamp,
    setSelectedTimestamp,
    isLive,
    handleLiveStreaming,
    streamTimestamp,
    playTimestamp,
    errorText,
    isPaused,
    isNotPlaying,
    handlePause,
    handleUnpause,
    setPlayTimestamp,
    handlePlaybackTime,
    handleChangeVolume,
    volume,
    muted,
  };
};
