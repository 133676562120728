import { TimeStampType } from "src/interface/TimeStamp";
import { addZero } from "./textUtil";

/**
 * 再生時間をタイムスタンプに変換する
 * @return {number} 単位はミリ秒 */
export const convertPlayTimestampToTimeStamp = (
  playTimestamp: TimeStampType | undefined
) => {
  if (!playTimestamp) return;
  const sampleString = `${playTimestamp.year}-${addZero(
    playTimestamp.month
  )}-${addZero(playTimestamp.date)}T${addZero(playTimestamp.hour)}:${addZero(
    playTimestamp.minute
  )}:${addZero(playTimestamp.second)}`;
  const sampleDate = new Date(sampleString);
  const sampleTimestamp = sampleDate.getTime();

  return sampleTimestamp;
};
