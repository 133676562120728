export const buildQueryString = (
  base: string,
  ...queryString: URLSearchParams[]
): string => {
  const builtQueryString = queryString
    .map((v) => v.toString())
    .filter((v) => v !== "")
    .join("&");
  return (
    base +
    (builtQueryString.length > 0
      ? (base.indexOf("?") !== -1 ? "&" : "?") + builtQueryString
      : "")
  );
};
