import { gql } from "urql";
import { useSigninMutation } from "src/gql/generated/graphql";
import { useState } from "react";
import { Cookies } from "react-cookie";
import { TOKEN_KEY, LOCAL_MEMORY, ERROR_TEXT } from "src/const";
import { useHistory } from "react-router-dom";
import { buildQueryString } from "src/helper/query-string";

export const SigninQuery = gql`
  mutation Signin($userId: String!, $password: String!) {
    signin(input: { userId: $userId, password: $password }) {
      __typename
      ... on SigninSuccess {
        token
      }
      ... on SigninFailed {
        message
      }
      ... on SigninFrozen {
        message
      }
      ... on SigninRejection {
        message
      }
    }
  }
`;

export const useLogin = () => {
  const [, signin] = useSigninMutation();
  const [userId, setUserId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [shopName, setShopName] = useState<string>("");
  const [apiKey, setApiKey] = useState<string>("");
  const [alertText, setAlertText] = useState<string>("");
  const history = useHistory();
  const login = async (path: string) => {
    // user_id, password, 店舗IDでのログイン
    await signin({ userId, password }).then(async (result: any) => {
      const { data } = result;
      const typename = data?.signin.__typename;
      switch (typename) {
        case "SigninSuccess": {
          const cookie = new Cookies();
          cookie.set(TOKEN_KEY, data?.signin.token ?? "", {
            maxAge: 10 * 24 * 60 * 60,
            sameSite: "none",
            secure: true,
          });
          if (shopName || apiKey) {
            const params = new URLSearchParams();
            if (shopName) {
              params.append("shop-name", shopName);
              localStorage.setItem(LOCAL_MEMORY.SHOP_NAME, shopName);
            }
            if (apiKey) {
              params.append("api-key", apiKey);
            }
            history.push(buildQueryString(path, params));
          } else {
            localStorage.removeItem(LOCAL_MEMORY.SHOP_NAME);
            history.push(path);
          }
          setAlertText("");
          return true;
        }
        case "SigninFailed":
          setAlertText(ERROR_TEXT.LOGIN_ERROR_SIGNIN_FAILED);
          return false;
        case "SigninFrozen":
          setAlertText(
            ERROR_TEXT.LOGIN_ERROR_SIGNIN_FROZEN
          );

          return false;
        case "SigninRejection":
          setAlertText(ERROR_TEXT.LOGIN_ERROR_SIGNIN_REJECTION);
          return false;
        default:
          return false;
      }
    });
  };

  return {
    userId,
    setUserId,
    password,
    setPassword,
    shopName,
    setShopName,apiKey, setApiKey,
    alertText,
    login,
  };
};
