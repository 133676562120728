import {
  Box,
  Flex,
  Slider,
  SliderTrack,
  SliderThumb,
  Icon,
} from "@chakra-ui/react";
import { FC, useRef, useState } from "react";
import { VolumeIcon } from "./VolumeIcon";

export type VolumeBarProps = {
  showVolumeSlider: boolean;
  setShowVolumeSlider: (showVolumeSlider: boolean) => void;
  onChange: (volume: number) => void;
  volume: number;
  muted: boolean;
  handlePause: () => void;
};

export const VolumeBar: FC<VolumeBarProps> = (props) => {
  const {
    showVolumeSlider,
    setShowVolumeSlider,
    onChange,
    volume,
    muted,
    handlePause,
  } = props;

  const [isDragging, setIsDragging] = useState(false);
  const [prevVolume, setPrevVolume] = useState(50);
  // ボリュームを調整した際に、レンダリングされ、調整された表示になるよう使用
  const [sliderVolume, setSliderVolume] = useState<number>(volume ?? 0);

  /** 初回のmute解除時のみpauseをかけるためのフラグ */
  const isFirstUnmute = useRef(true);

  const isMuted = sliderVolume === 0 || muted;

  const handleClickVolumeIcon = () => {
    if (isMuted) {
      onChange(prevVolume);
      setSliderVolume(prevVolume);

      //NOTE: ブラウザとsafieの仕様のため最初はmuteを解除しても、音が出ない
      // そのため、最初のmute解除時だけはpauseをかける
      if (isFirstUnmute.current) {
        isFirstUnmute.current = false;
        handlePause();
      }

      return;
    }
    setPrevVolume(volume);
    onChange(0);
    setSliderVolume(0);
  };

  return (
    <Flex
      position="relative"
      cursor="pointer"
      onMouseEnter={() => {
        setShowVolumeSlider(true);
      }}
    >
      {showVolumeSlider && (
        <Box
          px="8px"
          py="16px"
          position="absolute"
          bottom="26px"
          right="-8px"
          backgroundColor="rgba(0,0,0,0.8)"
          onMouseEnter={() => {
            setShowVolumeSlider(true);
          }}
          onMouseLeave={() => {
            if (isDragging) return;
            setShowVolumeSlider(false);
          }}
        >
          <Slider
            aria-label="slider-ex-3"
            min={0}
            max={100}
            value={sliderVolume}
            orientation="vertical"
            minH="32"
            onChangeStart={() => {
              setIsDragging(true);
            }}
            onChange={setSliderVolume}
            onChangeEnd={(e) => {
              setIsDragging(false);
              setPrevVolume(volume);
              onChange(e);
            }}
          >
            <SliderTrack width="10px" borderRadius={5} />
            <SliderThumb width="20px" height="20px" />
          </Slider>
        </Box>
      )}
      <Icon
        onClick={handleClickVolumeIcon}
        as={VolumeIcon}
        width="20px"
        height="20px"
        color="white"
        muted={isMuted}
      />
    </Flex>
  );
};
